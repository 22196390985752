<template>
  <div class="not-found">
    <div class="logo" />
    <h3>Acesso negado</h3>
    <p>Parece que você não tem permissão para acessar essa página</p>
    <div class="container">
      <button-component
        design-type="default"
        font-size="20px"
        type="button"
        :rounded="false"
        :padding-v="'35px'"
        :padding-h="'14px'"
        @click="goBack"
      >
        Voltar
      </button-component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forbidden',
  methods: {
    goBack () {
      this.$router.push('/imobiliaria/imoveis/anunciados')
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.not-found {
  background-position: center 120px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  .logo {
    display: block;
    display: block;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    width: 190px;
    height: 35px;
  }

  h3,
  p {
    display: block;
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
    color: $brand-color;
  }

  h3 {
    margin-top: 30px;
  }

  .container {
    display: flex;
    margin-top: 44px;
    justify-content: center;
  }
}
</style>
